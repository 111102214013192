import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from 'src/app/shared/models/http-response';
import { UnauthenticatedApiClient } from 'src/app/shared/services/http-api-client.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private httpApi: UnauthenticatedApiClient) { }

  public getCart(customerId: any): Observable<HttpResponse<any>> {
    return this.httpApi.get<any>(`/cart/${customerId}`);
  }

  public updateCart(cartData: any): Observable<HttpResponse<any>> {
    return this.httpApi.post<any>(`/cart`, cartData);
  }

}

